import * as most from 'most'
import createAPI from '../api/nextrip'

export const EVENTS = {
  CLEAR_DEPARTURES: 'nextrip/CLEAR_DEPARTURES',
  FETCH_DEPARTURES: 'nextrip/FETCH_DEPARTURES',
  FETCH_DEPARTURES_FAILURE: 'nextrip/FETCH_DEPARTURES_FAILURE',
  FETCH_DEPARTURES_SUCCESS: 'nextrip/FETCH_DEPARTURES_SUCCESS',
}

const initialState = {
  isFetching: false,
  departureList: [],
  error: null,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EVENTS.CLEAR_DEPARTURES: {
      return {
        ...state,
        departureList: [],
        isFetching: true,
      }
    }
    case EVENTS.FETCH_DEPARTURES: {
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    }
    case EVENTS.FETCH_DEPARTURES_SUCCESS: {
      return {
        ...state,
        departureList: action.departures,
        error: null,
        isFetching: false,
      }
    }
    case EVENTS.FETCH_DEPARTURES_FAILURE: {
      return {
        ...state,
        departureList: [],
        isFetching: false,
        error: action.error,
      }
    }
    default:
      return state
  }
}

const clearDepartures = () => ({ type: EVENTS.CLEAR_DEPARTURES })

const fetchDepartures = siteId => ({ type: EVENTS.FETCH_DEPARTURES, siteId })

const fetchDeparturesSuccess = departures => ({ type: EVENTS.FETCH_DEPARTURES_SUCCESS, departures })

const fetchDeparturesFailure = error => ({
  type: EVENTS.FETCH_DEPARTURES_FAILURE,
  error,
})

export const actions = {
  clearDepartures,
  fetchDepartures,
  fetchDeparturesSuccess,
  fetchDeparturesFailure,
}

export const epic = (action$, store) =>
  action$
    .filter(action => action.type === EVENTS.FETCH_DEPARTURES)
    .flatMap(({ siteId }) => {
      return most
        .fromPromise(createAPI(store).Departure.forSite({ siteId }))
        .flatMap(response => {
          return most.of(fetchDeparturesSuccess(response.data()))
        })
        .recoverWith(error => most.of(fetchDeparturesFailure(error)))
    })
