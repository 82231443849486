import React from 'react'
import { Svg, Path } from 'react-native-svg'

interface Props {
  color: string
  size: number
}

const Schedule: React.FC<Props> = ({ size = 44, color = '#999' }) => (
  <Svg viewBox="0 0 1000 1000" width={size} height={size}>
    <Path
      d="M132.5,163.1V40.6c0-16.9,13.7-30.6,30.6-30.6s30.6,13.7,30.6,30.6v122.5c0,16.9-13.7,30.6-30.6,30.6S132.5,180.1,132.5,163.1z M653.1,193.8c16.9,0,30.6-13.7,30.6-30.6V40.6c0-16.9-13.7-30.6-30.6-30.6s-30.6,13.7-30.6,30.6v122.5C622.5,180.1,636.2,193.8,653.1,193.8z M990,745c0,135.3-109.7,245-245,245S500,880.3,500,745s109.7-245,245-245S990,609.7,990,745z M928.8,745c0-101.3-82.4-183.8-183.8-183.8S561.3,643.7,561.3,745c0,101.4,82.4,183.8,183.8,183.8S928.8,846.4,928.8,745z M255,377.5H132.5V500H255V377.5z M132.5,683.8H255V561.3H132.5V683.8z M316.3,500h122.5V377.5H316.3V500z M316.3,683.8h122.5V561.3H316.3V683.8z M71.3,738.9V316.3H745v122.5h61.3V199.8c0-37.2-29.7-67.3-66.3-67.3h-25.5v30.6c0,33.8-27.4,61.3-61.3,61.3s-61.3-27.5-61.3-61.3v-30.6H224.4v30.6c0,33.8-27.5,61.3-61.3,61.3s-61.3-27.5-61.3-61.3v-30.6H76.4c-36.6,0-66.4,30.1-66.4,67.3v539.1c0,37.1,29.7,67.3,66.4,67.3h362.4V745H76.4C73.6,745,71.3,742.1,71.3,738.9z M622.5,500V377.5H500V500H622.5z M836.9,745H745v-91.9c0-16.9-13.7-30.6-30.6-30.6s-30.6,13.7-30.6,30.6v122.5c0,16.9,13.7,30.6,30.6,30.6h122.5c16.9,0,30.6-13.7,30.6-30.6S853.8,745,836.9,745z"
      fill={color}
    />
  </Svg>
)

export default Schedule
