import React, { Fragment } from 'react'
import { propEq } from 'ramda'
import { connect } from 'react-redux'
import { actions as nextripActions } from 'store/nextrip'
import { actions as savedStopActions } from 'store/savedStops'
import { actions as busStopActions } from 'store/busStop'

import MyStopsListComponent from './MyStopsList'
import withNavigation from 'navigation/withNavigation'
import ROUTE_NAMES from 'navigation/routeNames'
import { StatusBar } from 'react-native'

const mapStateToProps = state => ({
  nav: state.nav,
  departureList: state.nextrip.departureList,
  savedStopList: state.savedStops.savedStopList,
  savedStopOrder: state.savedStops.savedStopOrder,
  isLoading: state.nextrip.isFetching,
})

const mapDispatchToProps = {
  fetchDepartures: nextripActions.fetchDepartures,
  removeSavedStop: savedStopActions.removeSavedStop,
  reorderSavedStop: savedStopActions.reorderSavedStop,
  storeStop: busStopActions.storeStop,
}

const MyStopsList = props => {
  const tapSavedStop = siteid => {
    const { navigate, storeStop, savedStopList } = props

    const stop = Object.values(savedStopList)
      .map(ss => ss.stop)
      .find(propEq('id', siteid))

    storeStop(stop)

    navigate(ROUTE_NAMES.DEPARTURE_LIST, { siteid })
  }

  return (
    <Fragment>
      <StatusBar backgroundColor="black" barStyle="light-content" />
      <MyStopsListComponent
        {...props}
        tapFindStop={() => props.navigate(ROUTE_NAMES.FIND_STOP)}
        tapSavedStop={tapSavedStop}
      />
    </Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigation(MyStopsList))
