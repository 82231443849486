import React, { Component, Fragment } from 'react'
//import PropTypes from 'prop-types'
import { SwipeableListView, TouchableHighlight, Text, View, Button, StyleSheet } from 'react-native'
import Header from 'components/ui/Header'
import MyStopsListItem from './MyStopsListItem'
import { withTranslation } from 'react-i18next'
import SearchIcon from 'components/ui/SearchIcon'

class MyStopsList extends Component {
  emptyList = () => (
    <View style={styles.getStartedBox}>
      <Text style={styles.getStartedText}>{this.props.t('get_started')}</Text>
    </View>
  )

  genDataSource(rowData) {
    const dataBlob = {}
    const sectionIDs = ['Section 0']
    const rowIDs = [[]]

    dataBlob['Section 0'] = {}
    rowData.forEach((el, index) => {
      const rowName = `${index}`
      dataBlob[sectionIDs[0]][rowName] = {
        id: rowName,
        ...el,
      }
      rowIDs[0].push(rowName)
    })

    return [dataBlob, sectionIDs, rowIDs]
  }

  renderSeparator = (sectionID, rowID) => {
    return <View key={`${sectionID}-${rowID}`} style={styles.separator} />
  }

  renderCustomQuickActions = rowData => {
    const { t } = this.props
    return (
      <View style={styles.actionsContainer}>
        <TouchableHighlight
          style={[styles.actionButton, { backgroundColor: 'red' }]}
          underlayColor="transparent"
          onPress={() => this.onDeleteRow(rowData.id)}
        >
          <Text style={styles.buttonText}>{t('delete')}</Text>
        </TouchableHighlight>
      </View>
    )
  }

  onDeleteRow(index) {
    this.props.removeSavedStop(index)
  }

  onSelectRow(rowID) {
    const openID = this.state.dataSource.getOpenRowID()

    if (openID && openID === rowID) {
      this.setState({
        dataSource: this.state.dataSource.setOpenRowID(null),
      })
    }
  }

  renderRow = rowData => {
    const { t, removeSavedStop } = this.props

    return (
      <MyStopsListItem
        savedStop={rowData}
        t={t}
        removeSavedStop={removeSavedStop}
        tapSavedStop={this.props.tapSavedStop}
      />
    )
  }

  render() {
    const { t, savedStopList, savedStopOrder } = this.props

    const orderedStops = savedStopOrder.map(id => ({ key: id, ...savedStopList[id] }))

    const ds = SwipeableListView.getNewDataSource()
    const dataSource = ds.cloneWithRowsAndSections(...this.genDataSource(orderedStops))

    const listIsEmpty = Object.keys(savedStopList).length === 0

    return (
      <Fragment>
        <Header
          leftButton={<View />}
          title={t('my_stops')}
          rightButton={
            <View onClick={this.props.tapFindStop}>
              <SearchIcon size={27} color="#fff" />
            </View>
          }
        />

        {listIsEmpty && (
          <Button
            color="#229955"
            title={this.props.t('find_my_stop')}
            onPress={this.props.tapFindStop}
          />
        )}

        <SwipeableListView
          style={styles.list}
          contentContainerStyle={styles.contentContainer}
          dataSource={dataSource}
          maxSwipeDistance={70}
          renderQuickActions={this.renderCustomQuickActions}
          renderRow={this.renderRow}
          renderSeparator={this.renderSeparator}
          enableEmptySections={true}
        />
      </Fragment>
    )
  }
}

const styles = StyleSheet.create({
  getStartedBox: {
    flex: 1,
    backgroundColor: 'black',
  },
  getStartedText: {
    padding: 10,
    fontSize: 18,
    color: 'white',
  },
  buttonText: { color: '#fff' },
  list: {
    flex: 1,
    backgroundColor: 'black',
    overflow: 'scroll',
  },
  contentContainer: {},
  rowContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingLeft: 20,
    paddingRight: 5,
    paddingVertical: 10,
    alignItems: 'center',
    backgroundColor: 'black',
    justifyContent: 'space-between',
    height: 70,
  },
  separator: {
    height: 1,
    alignSelf: 'stretch',
    backgroundColor: 'lightgray',
  },
  actionsContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  actionButton: {
    width: 70,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

// MyStopsList.propTypes = {
//   savedStopList: PropTypes.object.isRequired,
//   savedStopOrder: PropTypes.array.isRequired,
//   reorderSavedStop: PropTypes.func.isRequired,
//   removeSavedStop: PropTypes.func.isRequired,
//   isLoading: PropTypes.bool.isRequired,
//   tapFindStop: PropTypes.func.isRequired,
//
//   // from withTranslation
//   t: PropTypes.func.isRequired,
// }

export default withTranslation('myStopsList')(MyStopsList)
