import React from 'react'
import { prop } from 'ramda'
import { Text, View, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import BusStopModel from 'models/BusStopModel'
import ScheduleIcon from 'components/ui/icons/Schedule'
import { withTranslation } from 'react-i18next'
import { isWeb } from 'utils/platformSelect'

const styles = {
  container: {
    backgroundColor: '#444',
  },
  nickname: {
    fontSize: 30,
    color: '#fff',
    textAlign: 'center',
    marginBottom: 3,
  },
  locationLabel: {
    fontSize: 18,
    color: '#fff',
    textAlign: 'center',
    marginBottom: 3,
  },
  directions: {
    fontSize: 12,
    color: '#ddd',
    textAlign: 'center',
    marginBottom: 3,
  },
}

const renderNickname = savedStop => {
  const nickname = prop('nickname', savedStop)

  return nickname ? <Text style={styles.nickname}>{nickname}</Text> : null
}

const DepartureListHeader = ({ stop, savedStop, showSchedule, t }) => {
  const stopData = savedStop ? savedStop.stop : stop
  const stopModel = BusStopModel.fromStopFeature(stopData)

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          {renderNickname(savedStop)}
          <Text style={styles.locationLabel}>{stopModel.getLocationLabel()}</Text>
          <Text numberOfLines={1} style={styles.directions}>
            {stopModel.getDirections(t)}
          </Text>
        </View>

        {isWeb && (
          <TouchableOpacity style={{ padding: 7 }} onPress={() => showSchedule(stop)}>
            <ScheduleIcon color="#fff" size={30} />
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
}

DepartureListHeader.propTypes = {
  savedStop: PropTypes.shape({
    nickname: PropTypes.string,
  }),
  stop: PropTypes.object,
  t: PropTypes.func.isRequired,
}

export default withTranslation('departureList')(DepartureListHeader)
