import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import history from 'navigation/history'
import routes from 'navigation/routes'
import TestComponent from './TestComponent'

export default class App extends React.Component {
  state = {
    error: null,
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  componentDidCatch(error, errorInfo) {
    alert(error, errorInfo)
  }

  render() {
    if (this.state.error) {
      return (
        <span style={{ backgroundColor: '#000', color: '#999' }}>
          {JSON.stringify(this.state.error)}
        </span>
      )
    }

    return (
      <Router history={history}>
        <Switch>
          {Object.values(routes).map(route => (
            <Route key={route.path} path={route.path} component={route.component} exact />
          ))}
          <Route path="/test" component={TestComponent} exact />
        </Switch>
      </Router>
    )
  }
}
