import uuid from 'uuid/v4'
import { omit, without, union } from 'ramda'
import analytics from 'utils/analytics'

export const EVENTS = {
  SAVE_STOP: 'savedStops/SAVE_STOP',
  REORDER_SAVED_STOP: 'savedStops/REORDER_SAVED_STOP',
  REMOVE_SAVED_STOP: 'savedStops/REMOVE_SAVED_STOP',
  HIDE_ROUTE: 'savedStops/HIDE_ROUTE',
  SHOW_ALL_ROUTES: 'savedStops/SHOW_ALL_ROUTES',
}

const initialState = {
  savedStopList: {},
  savedStopOrder: [],
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EVENTS.SAVE_STOP: {
      let newSavedStopList = Object.assign({}, state.savedStopList)
      const newSavedStopId = uuid()
      const { stop, nickname, hiddenRoutes = [] } = action.payload
      newSavedStopList[newSavedStopId] = {
        id: newSavedStopId,
        nickname,
        stop,
        hiddenRoutes,
      }

      analytics().logEvent('save_stop', { nickname, siteid: stop.id })

      return {
        ...state,
        savedStopList: newSavedStopList,
        savedStopOrder: [newSavedStopId, ...state.savedStopOrder],
      }
    }

    case EVENTS.REORDER_SAVED_STOP: {
      const order = state.savedStopOrder.slice()
      const event = action.payload
      order.splice(event.to, 0, order.splice(event.from, 1)[0])
      return {
        ...state,
        savedStopOrder: order,
      }
    }

    case EVENTS.REMOVE_SAVED_STOP: {
      analytics().logEvent('remove_saved_stop', { siteid: action.payload })

      return {
        ...state,
        savedStopList: omit([action.payload], state.savedStopList),
        savedStopOrder: without([action.payload], state.savedStopOrder),
      }
    }

    case EVENTS.HIDE_ROUTE: {
      const subRoute = `${action.payload.route}${action.payload.terminal}`

      if (action.payload.savedStopId) {
        const newSavedStopList = { ...state.savedStopList }
        const savedStop = newSavedStopList[action.payload.savedStopId]

        analytics().logEvent('hide_route', { siteid: savedStop.id, subRoute })

        savedStop.hiddenRoutes = union(savedStop.hiddenRoutes, [subRoute])

        return {
          ...state,
          savedStopList: newSavedStopList,
        }
      }
      return state
    }

    case EVENTS.SHOW_ALL_ROUTES: {
      if (action.payload) {
        const newSavedStopList = Object.assign({}, state.savedStopList)
        const savedStop = newSavedStopList[action.payload]

        analytics().logEvent('show_all_routes', { siteid: savedStop.id })

        savedStop.hiddenRoutes = []
        return {
          ...state,
          savedStopList: newSavedStopList,
        }
      }
      return state
    }
    default:
      return state
  }
}

const saveStop = (stop, nickname) => {
  return {
    type: EVENTS.SAVE_STOP,
    payload: {
      stop,
      nickname,
    },
  }
}
const reorderSavedStop = e => {
  return { type: EVENTS.REORDER_SAVED_STOP, payload: e }
}
const removeSavedStop = savedStopId => {
  return { type: EVENTS.REMOVE_SAVED_STOP, payload: savedStopId }
}
const hideRoute = (savedStopId, route, terminal) => {
  return { type: EVENTS.HIDE_ROUTE, payload: { savedStopId, route, terminal } }
}
const showAllRoutes = savedStopId => {
  return { type: EVENTS.SHOW_ALL_ROUTES, payload: savedStopId }
}

export const actions = {
  saveStop,
  reorderSavedStop,
  removeSavedStop,
  hideRoute,
  showAllRoutes,
}
