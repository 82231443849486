import React from 'react'
import { Svg, Path } from 'react-native-svg'

interface Props {
  color: string
  size: number
}

const Alert: React.FC<Props> = ({ size = 44, color = '#999' }) => (
  <Svg viewBox="0 0 96 96" width={size} height={size}>
    <Path
      d="M51.6,21.1c-0.7-1.3-2.1-2.1-3.6-2.1c-1.5,0-2.9,0.8-3.6,2.1L16.1,70.7c-0.7,1.3-0.7,2.9,0,4.1
      c0.8,1.3,2.2,2.1,3.6,2.1h56.7c1.5,0,2.9-0.8,3.6-2.1c0.7-1.3,0.7-2.9,0-4.1L51.6,21.1z M52.2,68.7h-8.3v-8.3h8.3V68.7z M52.2,56.2
      h-8.3V39.7h8.3V56.2z"
      fill={color}
    />
  </Svg>
)

export default Alert
