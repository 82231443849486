const DAYS: string[] = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
]

const daysFromDayFlags = (flags: string): Array<string | boolean> =>
  flags
    .split('')
    .map(flag => flag === '1')
    .map((active, index) => (active ? DAYS[index] : false))
    .filter(Boolean)

export default daysFromDayFlags
