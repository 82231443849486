import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { actions as nextripActions } from 'store/nextrip'
import { actions as savedStopActions } from 'store/savedStops'
import { actions as busStopActions } from 'store/busStop'
import DepartureListComponent from './DepartureList'
import getRouteParam from 'utils/getRouteParam'
import { stopForSiteId, savedStopForSiteId, getVisibleDepartures } from 'store/selectors'
import withNavigation from 'navigation/withNavigation'
import ROUTE_NAMES from 'navigation/routeNames'
import departureFilterForHiddens from 'utils/departureFilterForHiddens'
import analytics from 'utils/analytics'
import geoDistance from 'utils/geoDistance'
import displayDistance from 'utils/displayDistance'

const mapStateToProps = (state, ownProps) => {
  const siteid = getRouteParam(ownProps, 'siteid')

  return {
    siteid,
    departureList: state.nextrip.departureList,
    isLoadingDepartures: state.nextrip.isFetching,
    isLoadingStop: state.busStop.isLoading,
    stop: stopForSiteId(state, siteid),
    savedStop: savedStopForSiteId(state, siteid),
    visibleDepartures: getVisibleDepartures(state, siteid),
  }
}

const mapDispatchToProps = {
  clearDepartures: nextripActions.clearDepartures,
  fetchDepartures: nextripActions.fetchDepartures,
  hideRoute: savedStopActions.hideRoute,
  showAllRoutes: savedStopActions.showAllRoutes,
  fetchStop: busStopActions.fetchStop,
  saveStop: savedStopActions.saveStop,
}

class DepartureList extends Component {
  state = {
    tempHiddenRoutes: [],
  }

  constructor(props) {
    super(props)

    const { stop, fetchStop, isLoadingStop, siteid, fetchDepartures } = props

    if (!stop && !isLoadingStop) {
      fetchStop(siteid)
    }

    fetchDepartures(siteid)
  }

  onRefresh = () => {
    const { siteid, fetchDepartures } = this.props

    fetchDepartures(siteid)
  }

  hideRoute = ({ route, terminal }) => {
    const { savedStop } = this.props
    console.log({ savedStop, route, terminal })
    if (savedStop) {
      this.props.hideRoute(savedStop.id, route, terminal)
    } else {
      const subRoute = `${route}${terminal}`
      this.setState({ tempHiddenRoutes: [...this.state.tempHiddenRoutes, subRoute] })
    }
  }

  showAllRoutes = () => {
    if (this.props.savedStop) {
      this.props.showAllRoutes(this.props.savedStop.id)
    } else {
      this.setState({ tempHiddenRoutes: [] })
    }
  }

  showMap = ({ Route, Terminal }) => {
    const subRoute = `${Route}${Terminal}`

    this.props.navigate(
      ROUTE_NAMES.ROUTE_MAP,
      {
        routeNumber: Route,
        terminal: Terminal,
      },
      { route: subRoute }
    )
  }

  showSchedule = stop => {
    this.props.navigate(ROUTE_NAMES.STOP_SCHEDULE, {
      siteid: stop.id,
    })
  }

  addVehicleDistance = departure => {
    if (!departure.Actual) return departure

    const { savedStop, stop } = this.props

    const stopData = (savedStop && savedStop.stop) || stop

    const stopLocation = stopData.geometry.coordinates
    const vehicleLocation = [departure.VehicleLongitude, departure.VehicleLatitude]

    const vehicleDistance = geoDistance(
      vehicleLocation[1],
      vehicleLocation[0],
      stopLocation[1],
      stopLocation[0]
    )

    return { ...departure, vehicleDistance: displayDistance(vehicleDistance) }
  }

  getDisplayValues = () => {
    const { savedStop, departureList, visibleDepartures } = this.props

    if (savedStop) {
      return {
        visibleDepartures: visibleDepartures.map(this.addVehicleDistance),
        hiddenRoutes: savedStop.hiddenRoutes,
        hiddenDeparturesCount: departureList.length - visibleDepartures.length,
      }
    } else {
      const departureFilter = departureFilterForHiddens(this.state.tempHiddenRoutes)
      const tempVisibleDepartures = departureList.filter(departureFilter)
      return {
        visibleDepartures: tempVisibleDepartures.map(this.addVehicleDistance),
        hiddenRoutes: this.state.tempHiddenRoutes,
        hiddenDeparturesCount: departureList.length - tempVisibleDepartures.length,
      }
    }
  }

  handleSaveStop = (stop, nickname, hiddenRoutes) => {
    this.props.saveStop(stop, nickname, hiddenRoutes)

    analytics().logEvent('save_stop', {})

    this.props.goHome()
  }

  render() {
    const { departureList, savedStop, stop, isLoadingDepartures, isLoadingStop } = this.props

    if (!stop || !departureList) {
      console.log('no stop data available')
      return null
    }

    const { visibleDepartures, hiddenRoutes, hiddenDeparturesCount } = this.getDisplayValues()

    return (
      <DepartureListComponent
        componentId={this.props.componentId}
        departures={visibleDepartures}
        stop={stop}
        onSaveStop={this.handleSaveStop}
        savedStop={savedStop}
        hideRoute={this.hideRoute}
        showMap={this.showMap}
        showSchedule={this.showSchedule}
        hiddenRoutes={hiddenRoutes}
        hiddenDeparturesCount={hiddenDeparturesCount}
        showAllRoutes={this.showAllRoutes}
        isLoading={isLoadingStop || isLoadingDepartures}
        onRefresh={this.onRefresh}
        refreshing={isLoadingDepartures}
      />
    )
  }

  componentWillUnmount() {
    this.props.clearDepartures()
  }
}

DepartureList.propTypes = {
  clearDepartures: PropTypes.func.isRequired,
  departureList: PropTypes.array,
  showAllRoutes: PropTypes.func.isRequired,
  fetchDepartures: PropTypes.func.isRequired,
  fetchStop: PropTypes.func.isRequired,
  hideRoute: PropTypes.func.isRequired,
  siteid: PropTypes.string.isRequired,
  isLoadingDepartures: PropTypes.bool.isRequired,
  isLoadingStop: PropTypes.bool.isRequired,
  stop: PropTypes.object,
  savedStop: PropTypes.shape({ id: PropTypes.string }),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigation(DepartureList))
