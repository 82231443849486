export const GOOGLE_STREETVIEW_URL = 'https://maps.googleapis.com/maps/api/streetview'

export const ROUTE_REGION_PADDING = 0.03

export const TWIN_CITIES = {
  latitude: 44.98,
  longitude: -93.23,
  latitudeDelta: 0.3,
  longitudeDelta: 0.5,
}

export const DEFAULT_REGION = {
  latitude: 44.98,
  longitude: -93.26,
  latitudeDelta: 0.01,
  longitudeDelta: 0.01,
}
