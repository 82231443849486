import mirrorKeys from 'utils/mirrorKeys'

export default mirrorKeys([
  'MY_STOPS',
  'FIND_STOP',
  'ROUTE_MAP',
  'DEPARTURE_LIST',
  'DEPARTURES_HELP',
  'STOP_DETAIL',
  'PRIVACY_POLICY',
])
