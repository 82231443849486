import React from 'react'
import { View, Text } from 'react-native'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import BackButton from '../BackButton'

const styles = {
  bar: {
    height: 44,
    margin: 0,
    backgroundColor: '#333',
    textAlign: 'center',
  },
  leftButton: { position: 'absolute', left: 0, padding: 7 },
  title: { display: 'inline-block', marginLeft: 'auto', padding: 7, marginRight: 'auto' },
  titleText: { fontSize: 20, color: '#fff' },
  rightButton: { position: 'absolute', right: 0, top: 0, padding: 7 },
}

const Header = ({ t, leftButton, title, rightButton }) => {
  return (
    <div style={styles.bar}>
      <View style={styles.leftButton}>{leftButton || <BackButton color="#fff" size={24} />}</View>

      <View style={styles.title}>
        <Text style={styles.titleText}>{title ? title : t('TC Bus Data')}</Text>
      </View>

      <View style={styles.rightButton}>{rightButton}</View>
    </div>
  )
}

export default withTranslation()(withRouter(Header))
