import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Text, View } from 'react-native'

const styles = {
  hiddenRoutesFooter: {
    height: 30,
    width: '100%',
    marginTop: 7,
    marginBottom: 90,
    flexDirection: 'row' as 'row',
  },
  showAllButton: { color: 'white', paddingRight: 15, textAlign: 'right' as 'right' },
}

interface Props {
  hiddenRoutes: any[]
  showAllRoutes: (event: any) => void
}

const HiddenRoutesFooter: React.FC<Props & WithTranslation> = ({
  t,
  hiddenRoutes,
  showAllRoutes,
}) => {
  if (hiddenRoutes.length === 0) return null

  return (
    <View style={styles.hiddenRoutesFooter}>
      <View style={{ flex: 1 }} />
      <View>
        <Text style={styles.showAllButton}>
          {t('hidden')}: {hiddenRoutes.join(', ')}
        </Text>
        <Text style={styles.showAllButton} onPress={showAllRoutes}>
          {t('show_all_routes')}
        </Text>
      </View>
    </View>
  )
}

export default withTranslation('HiddenRoutesFooter')(HiddenRoutesFooter)
