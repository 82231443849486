import forge from 'mappersmith'
import { isWeb } from 'utils/platformSelect'

let _encodeURIComponent

const PreserveSlashesMiddleware = () => ({
  prepareRequest(next) {
    return next().then(request => {
      _encodeURIComponent = encodeURIComponent
      // eslint-disable-next-line
      encodeURIComponent = path => path
      return request
    })
  },

  response(next) {
    return next().then(response => {
      // eslint-disable-next-line
      encodeURIComponent = _encodeURIComponent
      return response
    })
  },
})

const createAPI = () =>
  forge({
    host: isWeb ? '/' : 'https://www.tcbusdata.com',
    middleware: [PreserveSlashesMiddleware],
    resources: {
      Schedules: {
        forStop: { path: '/data/schedules/v1/{sitePath}' },
      },
    },
  })

export default createAPI
