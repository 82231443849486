import React from 'react'
import { Text, View, TouchableOpacity } from 'react-native'
import colorForRoute from 'utils/colorForRoute'
import Map from 'components/ui/icons/Map'
import Hide from 'components/ui/icons/Hide'

const styles = {
  row: departure => ({
    flex: 1,
    flexDirection: 'row',
    backgroundColor: departure.Actual ? '#dfd' : 'white',
    borderBottomColor: '#999',
    borderBottomWidth: 0.5,
  }),
  routeBox: Route => {
    return {
      borderColor: colorForRoute(Route),
      borderWidth: 6,
      margin: 5,
      minWidth: 55,
    }
  },
  routeLabel: {
    fontSize: 28,
    fontWeight: 'bold',
    marginLeft: 2,
    marginRight: 2,
    textAlign: 'center',
  },
  infoSection: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  departureTextHolder: {
    marginLeft: 4,
    marginTop: 4,
    flexDirection: 'row',
  },
  departureText: departure => {
    return {
      fontSize: 24,
      fontWeight: 'bold',
      color: departure.Actual ? 'black' : '#444',
      marginBottom: 0,
    }
  },
  vehicleDistance: {
    fontSize: 20,
    marginLeft: 15,
    marginTop: 3.5,
  },
  description: {
    fontSize: 12,
    marginBottom: 5,
    marginLeft: 5,
  },
  hideButton: {
    backgroundColor: 'red',
    flex: 1,
    justifyContent: 'center',
    width: 75,
  },
  hideButtonText: {
    color: '#ffffff',
    fontSize: 22,
    textAlign: 'center',
    width: 75,
  },
  mapButton: {
    backgroundColor: 'green',
    flex: 1,
    justifyContent: 'center',
    width: 75,
  },
  mapButtonText: {
    color: '#ffffff',
    fontSize: 22,
    textAlign: 'center',
    width: 75,
  },
}

const DepartureListItem = ({ departure, showMap, hideRoute }) => {
  const onShowMap = () => {
    showMap(departure)
  }

  const onHideRoute = () => {
    console.log({ departure })
    hideRoute({ route: departure.Route, terminal: departure.Terminal })
  }

  return (
    <View style={styles.row(departure)}>
      <View style={styles.routeBox(departure.Route)}>
        <Text style={styles.routeLabel}>
          {departure.Route}
          {departure.Terminal}
        </Text>
      </View>

      <View style={styles.infoSection}>
        <View style={styles.departureTextHolder}>
          <Text style={styles.departureText(departure)}>{departure.DepartureText}</Text>
          <Text style={styles.vehicleDistance}>{departure.vehicleDistance}</Text>
        </View>
        <Text numberOfLines={1} style={styles.description}>
          {departure.Description}
        </Text>
      </View>

      <TouchableOpacity onPress={onHideRoute} style={{ margin: 6 }}>
        <Hide size={30} color={'#666'} />
      </TouchableOpacity>

      <TouchableOpacity onPress={onShowMap} style={{ margin: 6 }}>
        <Map size={26} color={'#666'} />
      </TouchableOpacity>
    </View>
  )
}

export default DepartureListItem
