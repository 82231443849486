import * as most from 'most'
import mirrorKeys from 'utils/mirrorKeys'

export const EVENTS = mirrorKeys(
  ['GET_CURRENT_POSITION', 'GET_CURRENT_POSITION_FAILURE', 'GET_CURRENT_POSITION_SUCCESS'],
  'geolocation/'
)

const initialState = {
  position: null,
  error: null,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EVENTS.GET_CURRENT_POSITION_SUCCESS:
      return {
        ...state,
        position: payload.position,
        error: null,
      }

    case EVENTS.GET_CURRENT_POSITION_FAILURE:
      return {
        ...state,
        error: payload,
      }

    default:
      return state
  }
}

const getCurrentPosition = () => ({
  type: EVENTS.GET_CURRENT_POSITION,
})

const getCurrentPositionSuccess = position => ({
  type: EVENTS.GET_CURRENT_POSITION_SUCCESS,
  payload: { position },
})
const getCurrentPositionFailure = error => ({
  type: EVENTS.GET_CURRENT_POSITION_FAILURE,
  error: true,
  payload: error,
})

export const actions = {
  getCurrentPosition,
  getCurrentPositionSuccess,
  getCurrentPositionFailure,
}

export const epic = action$ =>
  action$
    .filter(action => action.type === EVENTS.GET_CURRENT_POSITION)
    .flatMap(() => {
      if (!navigator.geolocation) {
        return most.of(getCurrentPositionFailure(new Error('browser does not support geolocation')))
      }

      const getPositionPromise = new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject)
      })

      return most
        .fromPromise(getPositionPromise)
        .flatMap(position => most.of(getCurrentPositionSuccess(position)))
        .recoverWith(error => most.of(getCurrentPositionFailure(error)))
    })
