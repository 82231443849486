import React from 'react'

import { View, Text, SectionList } from 'react-native'
import Header from 'components/ui/Header'
import ScheduleHeader from './Header'
import ScheduleItem from './ScheduleItem'
import { withTranslation } from 'react-i18next'
import dateFromSchedule from 'utils/dateFromSchedule'
import colorForRoute from 'utils/colorForRoute'
import { groupBy, prop } from 'ramda'

const styles = {
  container: { flex: 1 },
}

const routeLabelForSection = section => {
  if (section.data && section.data[0]) {
    const { route_long_name } = section.data[0]

    if (route_long_name === 'METRO Blue Line') return 'Blue'
    if (route_long_name === 'METRO Green Line') return 'Grn'
  }

  return routeNameFromBusText(section.trip_headsign)
}

const routeNameFromBusText = busText => busText.split(' ')[1]

const StopSchedule = ({ stopSchedules, t, stop, savedStop, updatedAt }) => {
  const updatedDate = new Date(updatedAt)

  const now = new Date()

  const forwardSchedules = stopSchedules.filter(schedule => {
    const endDate = dateFromSchedule(schedule.end_date)

    return endDate.getTime() > now.getTime() - 24 * 60 * 60 * 1000
  })

  const routeGroups = groupBy(prop('trip_headsign'))(forwardSchedules)

  const routeSections = Object.keys(routeGroups).map(trip_headsign => ({
    trip_headsign,
    data: routeGroups[trip_headsign],
  }))

  const SectionHeader = ({ section }) => {
    const { trip_headsign } = section
    const routeLabel = routeLabelForSection(section)

    return (
      <View style={{ flexDirection: 'row' }}>
        <View style={{ backgroundColor: colorForRoute(routeLabel), paddingHorizontal: 15 }}>
          <Text style={{ color: '#000', fontWeight: 'bold', fontSize: 45 }}>{routeLabel}</Text>
        </View>

        <View style={{ flex: 1, backgroundColor: '#ccc', paddingHorizontal: 15 }}>
          <Text style={{ paddingTop: 7, fontSize: 12 }}>{trip_headsign}</Text>
        </View>
      </View>
    )
  }

  const Footer = () => (
    <Text style={{ color: '#fff', textAlign: 'right', padding: 10, fontStyle: 'italic' }}>
      {t('updated')} {updatedDate.toLocaleString('default', { month: 'short' })}{' '}
      {updatedDate.getDate()} {updatedDate.getFullYear()}
    </Text>
  )

  return (
    <View style={styles.container}>
      <Header title={t('title')} />

      <ScheduleHeader stop={stop} savedStop={savedStop} />

      <SectionList
        keyExtractor={(item, index) => item + index}
        sections={routeSections}
        renderSectionHeader={section => <SectionHeader {...section} />}
        stickySectionHeadersEnabled={true}
        renderItem={({ item }) => <ScheduleItem schedule={item} t={t} />}
        ListFooterComponent={Footer}
      />
    </View>
  )
}

export default withTranslation('stopSchedule')(StopSchedule)
