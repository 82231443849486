import { combineReducers } from 'redux'
import { reducer as nextripReducer } from './nextrip'
import { reducer as vehicleLocationsReducer } from './vehicleLocations'
import { reducer as busStopReducer } from './busStop'
import { reducer as mngisRouteGeometryReducer } from './mngisRouteGeometry'
import { reducer as savedStopsReducer } from './savedStops'
import { reducer as geolocationReducer } from './geolocation'
import { reducer as stopSchedulesReducer } from './stopSchedules'

export default () =>
  combineReducers({
    geolocation: geolocationReducer,
    nextrip: nextripReducer,
    vehicleLocations: vehicleLocationsReducer,
    busStop: busStopReducer,
    mngisRouteGeometry: mngisRouteGeometryReducer,
    savedStops: savedStopsReducer,
    stopSchedules: stopSchedulesReducer,
  })
