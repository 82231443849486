import forge from 'mappersmith'
import { isWeb } from 'utils/platformSelect'

const createAPI = () => {
  return forge({
    host: isWeb ? '/' : 'https://www.tcbusdata.com',
    resources: {
      Stop: {
        bySitePath: { path: '/data/bus_stop/v1/{sitePath}' },
      },
    },
  })
}

export default createAPI
