import mostAdapter from 'redux-observable-adapter-most'
import { combineEpics, createEpicMiddleware } from 'redux-observable'
import { persistStore, persistReducer } from 'redux-persist'
import AgnosticStorage from '../utils/AgnosticStorage'

import * as nextrip from './nextrip'
import * as vehicleLocations from './vehicleLocations'
import * as geolocation from './geolocation'
import * as busStop from './busStop'
import * as mngisRouteGeometry from './mngisRouteGeometry'
import * as savedStops from './savedStops'
import * as stopSchedules from './stopSchedules'

import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import getRootReducer from './reducers'
export { nextrip, vehicleLocations, busStop, mngisRouteGeometry, savedStops, geolocation }

const epic = combineEpics(
  nextrip.epic,
  vehicleLocations.epic,
  busStop.epic,
  mngisRouteGeometry.epic,
  geolocation.epic,
  stopSchedules.epic
)
const epicMiddleware = createEpicMiddleware(epic, { adapter: mostAdapter })

const persistConfig = {
  key: 'root',
  storage: AgnosticStorage,
  whitelist: ['savedStops'],
}

const persistedReducer = persistReducer(persistConfig, getRootReducer())

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(epicMiddleware, thunk))
)

export const persistor = persistStore(store)
