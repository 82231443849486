import React, { Component } from 'react'
import { connect } from 'react-redux'
import getRouteParam from 'utils/getRouteParam'
import { actions as mngisRouteGeometryActions } from 'store/mngisRouteGeometry'
import { actions as vehicleLocationsActions } from 'store/vehicleLocations'
import {
  vehicleLocations as selectVehicleLocations,
  vehicleLocationsLoading as selectVehicleLocationsLoading,
} from 'store/selectors'
import getVehicleType from 'utils/getVehicleType'
import geojsonFromPolylines from 'utils/geojsonFromPolylines'

import RouteMapView from './RouteMap'

const mapStateToProps = (state, ownProps) => {
  const routeNumber = getRouteParam(ownProps, 'routeNumber')

  return {
    routeNumber,
    polylines: state.mngisRouteGeometry.polylines,
    routeRegion: state.mngisRouteGeometry.routeRegion,
    vehicleLocations: selectVehicleLocations(state),
    loadingVehicleLocations: selectVehicleLocationsLoading(state),
  }
}

const mapDispatchToProps = {
  fetchGeometryFromMNGIS: mngisRouteGeometryActions.fetchGeometry,
  clearGeometry: mngisRouteGeometryActions.clearGeometry,
  fetchVehicleLocations: vehicleLocationsActions.fetchVehicleLocations,
}

class RouteMap extends Component {
  componentDidMount() {
    const { fetchGeometryFromMNGIS, routeNumber, fetchVehicleLocations } = this.props

    let routeQuery = routeNumber
    if (routeNumber === 'Blue') routeQuery = '901'
    if (routeNumber === 'Grn') routeQuery = '902'

    fetchGeometryFromMNGIS(routeQuery)
    fetchVehicleLocations(routeQuery)

    this.fetchVehicleLocationsInterval = setInterval(() => {
      fetchVehicleLocations(routeQuery)
    }, 10 * 1000)
  }

  componentWillUnmount() {
    clearInterval(this.fetchVehicleLocationsInterval)
  }

  render() {
    const {
      routeNumber,
      routeRegion,
      polylines,
      loadingVehicleLocations,
      vehicleLocations,
    } = this.props

    const vehicleLocationFeatures = {
      type: 'FeatureCollection',
      features: vehicleLocations.map(vl => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [vl.VehicleLongitude, vl.VehicleLatitude],
        },
        properties: {
          Bearing: vl.Bearing,
          Speed: vl.Speed,
          Route: `${vl.Route}${vl.Terminal}`,
        },
      })),
    }

    const routeGeojson = geojsonFromPolylines(polylines)

    return (
      <RouteMapView
        routeNumber={routeNumber}
        vehicleType={getVehicleType(routeNumber)}
        routeGeojson={routeGeojson}
        routeRegion={routeRegion}
        loadingVehicleLocations={loadingVehicleLocations}
        vehicleLocationFeatures={vehicleLocationFeatures}
      />
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RouteMap)
