import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { prop } from 'ramda'
import { geolocation } from 'store'
import { actions as busStopActions } from 'store/busStop'
import regionInTwinCities from 'utils/region_in_twin_cities'
import regionFromCoords from 'utils/regionFromCoords'
import { DEFAULT_REGION } from '../../constants'
import FindStopComponent from './FindStop'
import withNavigation from 'navigation/withNavigation'
import ROUTE_NAMES from 'navigation/routeNames'
import boundsFromRegion from 'utils/boundsFromRegion'

const mapStateToProps = state => ({
  dispatch: state.dispatch,
  geolocationPosition: state.geolocation.position,
  geolocationError: state.geolocation.error,
})

const mapDispatchToProps = {
  getCurrentPosition: geolocation.actions.getCurrentPosition,
  storeStop: busStopActions.storeStop,
}

class FindStop extends Component {
  state = { geolocationPosition: null, bounds: null }

  componentDidMount() {
    const { geolocationPosition, getCurrentPosition } = this.props

    const initialCoords = prop('coords', geolocationPosition)

    const initialRegion =
      initialCoords && regionInTwinCities(initialCoords)
        ? regionFromCoords(initialCoords)
        : DEFAULT_REGION

    this.setState({ bounds: boundsFromRegion(initialRegion) })

    getCurrentPosition()
  }

  componentWillReceiveProps = nextProps => {
    const { geolocationPosition } = nextProps
    if (!prop('coords', geolocationPosition)) return

    this.setState({ geolocationPosition: geolocationPosition.coords })
  }

  tapStopPin = stop => {
    this.props.storeStop(stop)
    this.props.navigate(ROUTE_NAMES.STOP_DETAIL, { siteid: stop.id })
  }

  render() {
    return (
      <FindStopComponent
        initialRegion={this.initialRegion}
        geolocationPosition={this.state.geolocationPosition}
        tapStopPin={this.tapStopPin}
        bounds={this.state.bounds}
      />
    )
  }
}

FindStop.propTypes = {
  getCurrentPosition: PropTypes.func.isRequired,
  geolocationError: PropTypes.object,
  geolocationPosition: PropTypes.object,
  storeStop: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigation(FindStop))
