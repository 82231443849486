import React from 'react'
import { Text, View } from 'react-native'
import PropTypes from 'prop-types'
import BusStopModel from 'models/BusStopModel'
import { withTranslation } from 'react-i18next'

const styles = {
  container: {
    maxWidth: 150,
  },
  locationLabel: {
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 3,
  },
  directions: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 3,
  },
}

const PopupContent = ({ stop, t }) => {
  const stopModel = BusStopModel.fromStopFeature(stop)

  return (
    <View style={styles.container}>
      <Text style={styles.locationLabel}>{stopModel.getLocationLabel()}</Text>
      <Text numberOfLines={1} style={styles.directions}>
        {stopModel.getDirections(t)}
      </Text>
    </View>
  )
}

PopupContent.propTypes = {
  stop: PropTypes.object,
  t: PropTypes.func.isRequired,
}

export default withTranslation('departureList')(PopupContent)
