import forge from 'mappersmith'
import EncodeJson from 'mappersmith/middlewares/encode-json'

const NEXTRIP_API_URL = 'https://svc.metrotransit.org/NexTrip'

const createAPI = () => {
  return forge({
    host: NEXTRIP_API_URL,
    middlewares: [EncodeJson],
    resources: {
      Locations: {
        forRoute: { path: '/VehicleLocations/{route}?format=json' },
      },
    },
  })
}

export default createAPI
