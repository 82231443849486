import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { actions as stopScheduleActions } from 'store/stopSchedules'
import { actions as busStopActions } from 'store/busStop'
import Loader from 'components/ui/Loader'
import Error from 'components/ui/Error'
import StopScheduleComponent from './StopSchedule'
import ScheduleModel from 'models/ScheduleModel'
import getRouteParam from 'utils/getRouteParam'
import { sortWith, ascend, descend, prop } from 'ramda'

import {
  savedStopForSiteId,
  stopScheduleData,
  stopSchedulesLoading,
  stopSchedulesError,
  stopForSiteId,
} from 'store/selectors'
import withNavigation from 'navigation/withNavigation'

const mapStateToProps = (state, ownProps) => {
  const siteid = getRouteParam(ownProps, 'siteid')

  return {
    siteid,
    isLoadingStopSchedule: stopSchedulesLoading(state),
    stopScheduleError: stopSchedulesError(state),
    isLoadingStop: state.busStop.isLoading,
    stop: stopForSiteId(state, siteid),
    savedStop: savedStopForSiteId(state, siteid),
    stopScheduleData: stopScheduleData(state, siteid),
  }
}

const mapDispatchToProps = {
  fetchStopSchedule: stopScheduleActions.fetchStopSchedule,
  fetchStop: busStopActions.fetchStop,
}

const StopSchedule = ({
  navigate,
  stop,
  savedStop,
  fetchStop,
  isLoadingStop,
  siteid,
  fetchStopSchedule,
  stopScheduleData,
  schedules,
  isLoadingStopSchedule,
  stopScheduleError,
}) => {
  useEffect(() => {
    if (!stop && !isLoadingStop) {
      fetchStop(siteid)
    }

    if (!stopScheduleData) {
      fetchStopSchedule(siteid)
    }
  }, [])

  if (isLoadingStop || isLoadingStopSchedule) {
    return <Loader />
  }

  if (stopScheduleError) {
    return <Error />
  }

  if (!stop || !stopScheduleData) {
    return null
  }

  const scheduleModels = stopScheduleData.schedules.map(s => new ScheduleModel(s))

  const sortSchedules = sortWith([
    descend(prop('isToday')),
    descend(prop('isException')),
    ascend(prop('start_date')),
  ])

  return (
    <StopScheduleComponent
      stop={stop}
      savedStop={savedStop}
      stopSchedules={sortSchedules(scheduleModels)}
      updatedAt={stopScheduleData.updatedAt}
    />
  )
}

StopSchedule.propTypes = {
  stopSchedule: PropTypes.array,
  fetchStopSchedule: PropTypes.func.isRequired,
  fetchStop: PropTypes.func.isRequired,
  siteid: PropTypes.string.isRequired,
  isLoadingStopSchedule: PropTypes.bool.isRequired,
  isLoadingStop: PropTypes.bool.isRequired,
  stop: PropTypes.object,
  savedStop: PropTypes.shape({ id: PropTypes.string }),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigation(StopSchedule))
