import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { MAPBOX_STYLE_URL_NO_STOPS, MAPBOX_ACCESS_TOKEN } from 'config'
import { withTranslation } from 'react-i18next'
import Header from 'components/ui/Header'
import colorForRoute from 'utils/colorForRoute'
import ReactMapboxGl, { GeoJSONLayer } from 'react-mapbox-gl'

const Map = ReactMapboxGl({
  accessToken: MAPBOX_ACCESS_TOKEN,
  dragRotate: false,
  pitchWithRotate: false,
})

const RouteMap = ({
  t,
  routeNumber,
  routeRegion,
  geolocationPosition,
  routeGeojson,
  loadingVehicleLocations,
  vehicleLocationFeatures,
  vehicleType,
}) => {
  const color = colorForRoute(routeNumber)

  const getBounds = ({ latitude, latitudeDelta, longitude, longitudeDelta }) => [
    [longitude - longitudeDelta / 2, latitude - latitudeDelta / 2],
    [longitude + longitudeDelta / 2, latitude + latitudeDelta / 2],
  ]

  const [bounds, setBounds] = useState(null)

  useEffect(() => {
    routeRegion && setBounds(getBounds(routeRegion))
  }, [routeRegion])

  return (
    <div style={{ height: 'calc(100vh - 40px)', width: '100%' }}>
      <Header title={t('route', { routeNumber })} />

      <Map
        style={MAPBOX_STYLE_URL_NO_STOPS}
        containerStyle={{
          height: '100%',
          width: '100%',
        }}
        fitBounds={bounds && bounds}
        fitBoundsOptions={{ duration: 0 }}
      >
        <GeoJSONLayer
          data={routeGeojson}
          lineLayout={{
            'line-cap': 'round',
            'line-join': 'bevel',
          }}
          linePaint={{
            'line-color': color,
            'line-width': 6,
          }}
        />
        {vehicleType === 'bus' && (
          <GeoJSONLayer
            data={vehicleLocationFeatures}
            symbolLayout={{
              'icon-allow-overlap': true,
              'icon-image': 'arrow',
              'icon-size': 0.7,
              'icon-anchor': 'left',
              'icon-offset': [17, 3],
              'icon-rotate': ['-', ['get', 'Bearing'], 90],
            }}
            symbolPaint={{
              'icon-opacity': loadingVehicleLocations ? 0.5 : 1,
            }}
          />
        )}
        {vehicleType === 'bus' && (
          <GeoJSONLayer
            data={vehicleLocationFeatures}
            symbolLayout={{
              'text-field': ['get', 'Route'],
              'text-offset': [0, 1.4],
              'text-size': 12,
              'text-allow-overlap': true,
            }}
            symbolPaint={{
              'text-halo-color': '#fff',
              'text-halo-width': 2,
              'text-opacity': loadingVehicleLocations ? 0.5 : 1,
            }}
          />
        )}
        <GeoJSONLayer
          data={vehicleLocationFeatures}
          symbolLayout={{
            'icon-image': vehicleType === 'bus' ? 'bus-15' : 'rail-light-15',
            'icon-size': 1.2,
          }}
          symbolPaint={{
            'icon-opacity': loadingVehicleLocations ? 0.5 : 1,
          }}
        />
      </Map>
    </div>
  )
}

RouteMap.propTypes = {
  t: PropTypes.func.isRequired,
  polylines: PropTypes.array,
  routeRegion: PropTypes.shape({}),
  vehicleLocations: PropTypes.object,
}

export default withTranslation('routeMap')(RouteMap)
