import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Text, View, TouchableHighlight } from 'react-native'
import BusStopModel from 'models/BusStopModel'

const styles = {
  row: {
    backgroundColor: 'white',
    borderBottomColor: '#ddd',
    borderBottomWidth: 0.5,
    paddingLeft: 5,
  },
  nickname: {
    fontSize: 42,
    fontWeight: '500',
  },
  location: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  directions: {
    marginTop: 3,
    fontSize: 11,
    marginBottom: 5,
  },
}

class MyStopsListItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      stopModel: BusStopModel.fromStopFeature(this.props.savedStop.stop),
    }
  }

  onTap = () => {
    this.props.tapSavedStop(this.props.savedStop.stop.id)
  }

  render() {
    const locationLabel = this.state.stopModel.getLocationLabel()
    const directions = this.state.stopModel.getDirections(this.props.t)
    const nickname = this.props.savedStop.nickname

    return (
      <TouchableHighlight onPress={this.onTap} {...this.props.sortHandlers}>
        <View style={styles.row}>
          {nickname.length > 0 && <Text style={styles.nickname}>{nickname}</Text>}
          <Text style={styles.location}>{locationLabel}</Text>
          <Text style={styles.directions}>{directions}</Text>
        </View>
      </TouchableHighlight>
    )
  }
}

MyStopsListItem.propTypes = {
  t: PropTypes.func.isRequired,
  savedStop: PropTypes.shape({
    nickname: PropTypes.string,
    stop: PropTypes.object,
  }),
}

export default MyStopsListItem
