import React from 'react'
import withNavigation from 'navigation/withNavigation'
import { View } from 'react-native'
import { Svg, Line, Path } from '../svg'
import { moveTo, lineTo } from '../svg/pathTools'

const styles = {
  line: {
    strokeWidth: 2,
    strokeLinecap: 'round',
    fill: 'none',
  },
}

const BackButton = ({ color, size = 22, padding = 2, goBack }) => {
  const halfSize = size / 2

  const pathParts = [moveTo(halfSize, 0), lineTo(0, halfSize), lineTo(halfSize, size)]

  return (
    <View
      onClick={event => {
        goBack()
        event.preventDefault()
        return false
      }}
    >
      <Svg
        width={size}
        height={size}
        viewBox={`${-padding} ${-padding} ${size + 2 * padding} ${size + 2 * padding}`}
      >
        <Line x1="0" x2={size} y1={halfSize} y2={halfSize} stroke={color} {...styles.line} />
        <Path d={pathParts.join(' ')} stroke={color} {...styles.line} />
      </Svg>
    </View>
  )
}

export default withNavigation(BackButton)
