const dateFromSchedule = (scheduleDate: number): Date => {
  const dateString = `${scheduleDate}`

  const year = dateString.substring(0, 4)
  const month = dateString.substring(4, 6)
  const day = dateString.substring(6, 8)

  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
}

export default dateFromSchedule
