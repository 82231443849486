import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { MAPBOX_STYLE_URL_NO_STOPS, MAPBOX_ACCESS_TOKEN } from 'config'
import { DEFAULT_REGION } from '../../constants'
import { withTranslation } from 'react-i18next'
import Header from 'components/ui/Header'
import PopupContent from './PopupContent'
import { STOPS_LAYER } from './stopsLayerStyle'
import ReactMapboxGl, { Popup } from 'react-mapbox-gl'

const Map = ReactMapboxGl({
  accessToken: MAPBOX_ACCESS_TOKEN,
  dragRotate: false,
  pitchWithRotate: false,
})

const FindStop = ({ t, bounds, geolocationPosition, tapStopPin }) => {
  const [center, setCenter] = useState([DEFAULT_REGION.longitude, DEFAULT_REGION.latitude])
  const [activeStop, setActiveStop] = useState(null)

  useEffect(() => {
    if (geolocationPosition) {
      setCenter([geolocationPosition.longitude, geolocationPosition.latitude])
    }
  }, [geolocationPosition])

  const onMouseMove = (map, event) => {
    var features = map.queryRenderedFeatures(event.point)
    if (features[0] && features[0].layer.id === 'mngis_stops') {
      setActiveStop(features[0])
    }
  }

  const onClick = (map, event) => {
    var features = map.queryRenderedFeatures(event.point)
    if (features[0] && features[0].layer.id === 'mngis_stops') {
      tapStopPin(features[0])
    }
  }

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <Header title={t('find_my_stop')} />

      <Map
        style={MAPBOX_STYLE_URL_NO_STOPS}
        containerStyle={{
          height: '100%',
          width: '100%',
        }}
        center={center && center}
        fitBounds={bounds && bounds}
        onMouseMove={onMouseMove}
        onClick={onClick}
        onStyleLoad={map => {
          map.on('mouseleave', 'mngis_stops', () => {
            setActiveStop(null)
          })

          map.addSource('stops', {
            type: 'vector',
            tiles: [`https://www.tcbusdata.com/tiles/mngis_stops/{z}/{x}/{y}.pbf`],
            minzoom: 1,
            maxzoom: 14,
          })

          map.addLayer(STOPS_LAYER)
        }}
      >
        {activeStop && (
          <Popup
            coordinates={activeStop.geometry.coordinates}
            offset={{ bottom: [0, -27], left: [5, -20], right: [-5, -20], top: [0, -8] }}
            style={{ borderRadius: 6, pointerEvents: 'none' }}
          >
            <PopupContent stop={activeStop} />
          </Popup>
        )}
      </Map>
    </div>
  )
}

FindStop.propTypes = {
  initialRegion: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }),
  tapStopPin: PropTypes.func.isRequired,
  geolocationRegion: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  bounds: PropTypes.array,
}

export default withTranslation('myStopsList')(FindStop)
