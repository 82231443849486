import React from 'react'

const TestComponent = () => {
  const setLocalhostCookie = () => {
    var cookieName = 'HelloWorld'
    var cookieValue = 'HelloWorld'
    var myDate = new Date()
    myDate.setMonth(myDate.getMonth() + 12)
    document.cookie =
      cookieName + '=' + cookieValue + ';expires=' + myDate + ';domain=localhost;path=/'
  }

  return <button onClick={setLocalhostCookie}>setCookie</button>
}

export default TestComponent
