import forge from 'mappersmith'

const MN_ARCGIS = 'https://arcgis.metc.state.mn.us'
const PATH = '/transit/rest/services/transit/TIM_BusRoutes/MapServer/0/query'

const createAPI = () => {
  return forge({
    host: MN_ARCGIS,
    middlewares: [mngisRouteGeometryMiddleware],
    resources: {
      Geometry: {
        forRoute: {
          path: PATH,
        },
      },
    },
  })
}

const mngisRouteGeometryMiddleware = () => ({
  request(request) {
    const route = request.requestParams.route
    delete request.requestParams.route

    const query = {
      f: 'json',
      where: `ROUTENUM = ${route}`,
      returnGeometry: true,
      outFields: 'LINE_ID,ROUTENUM,ROUTEDESCRIPTION',
      outSR: 4326,
    }

    return request.enhance({ params: query })
  },
})

export default createAPI
