import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ActivityIndicator, Text, View } from 'react-native'
import Header from 'components/ui/Header'
import DepartureListHeader from './Header'
import DepartureListItem from './ListItem'
import { PullToRefresh } from 'components/PullToRefresh'
import { withTranslation } from 'react-i18next'
import HiddenRoutesFooter from './HiddenRoutesFooter'

const COLORS = ['#549e3c', '#8d6cb9', '#3a78b1', '#ed8634', '#85584c']

const styles = {
  container: {
    flex: 1,
  },
  rightButton: {
    marginRight: 10,
  },
  noDataBox: {
    backgroundColor: '#000',
    height: 200,
  },
  noDataText: {
    padding: 10,
    fontSize: 18,
    color: '#fff',
  },
  hiddenRoutesText: {
    textAlign: 'center',
  },
  footer: {
    marginTop: 4,
  },
}

class DepartureList extends Component {
  showAllRoutes = () => {
    this.props.showAllRoutes(this.state.savedStopId)
    this.setState({
      hiddenRoutes: [],
    })
  }

  emptyList = () => (
    <View style={styles.noDataBox} pointerEvents={'box-only'}>
      <Text style={styles.noDataText}>{this.props.t('no_data')}</Text>
    </View>
  )

  onRefresh = () => {
    this.props.onRefresh()

    return new Promise((resolve, reject) => {
      console.log('ref')
      setTimeout(resolve, 1000)
    })
  }

  render = () => {
    const { departures, t, stop, savedStop, hiddenRoutes, showAllRoutes, showSchedule } = this.props

    return (
      <View style={styles.container}>
        <Header title={t('Departures')} />

        <DepartureListHeader stop={stop} savedStop={savedStop} showSchedule={showSchedule} />

        <PullToRefresh
          pullDownContent={
            <div style={{ color: '#fff', fontSize: 40, textAlign: 'center', padding: 10 }}>
              &#8595;
            </div>
          }
          releaseContent={
            <View style={{ flexDirection: 'row' }}>
              {COLORS.map(backgroundColor => (
                <View key={backgroundColor} style={{ flex: 1, backgroundColor, height: 5 }} />
              ))}
            </View>
          }
          refreshContent={
            <View style={{ overflow: 'visible' }}>
              <View style={{ flexDirection: 'row', marginTop: -100, overflow: 'visible' }}>
                {COLORS.map(backgroundColor => (
                  <View key={backgroundColor} style={{ flex: 1, backgroundColor, height: 105 }} />
                ))}
              </View>
              <View style={{ marginTop: 15 }}>
                <ActivityIndicator size="large" color="#fff" />
              </View>
            </View>
          }
          pullDownThreshold={73}
          onRefresh={this.onRefresh}
          triggerHeight={'auto'}
          startInvisible={true}
        >
          {departures.length === 0 ? this.emptyList() : null}

          {departures.map((rowData, index) => (
            <DepartureListItem
              key={index}
              t={t}
              hideRoute={this.props.hideRoute}
              showMap={this.props.showMap}
              departure={rowData}
            />
          ))}

          <HiddenRoutesFooter showAllRoutes={showAllRoutes} hiddenRoutes={hiddenRoutes} />
        </PullToRefresh>
      </View>
    )
  }
}

DepartureList.propTypes = {
  t: PropTypes.func.isRequired,
  departures: PropTypes.array,
  showAllRoutes: PropTypes.func.isRequired,
  hideRoute: PropTypes.func.isRequired,
  hiddenRoutesCount: PropTypes.number,
  hiddenDeparturesCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  stop: PropTypes.object,
  savedStop: PropTypes.object,
}

export default withTranslation('departureList')(DepartureList)
