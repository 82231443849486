import React from 'react'

export const Svg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} ref={props.refProp} />
)

export const G = props => <g {...props} />
export const Line = props => <line {...props} />
export const Circle = props => <circle {...props} />
export const Rect = props => <rect {...props} />
export const Text = props => <text {...props} />
export const Path = props => <path {...props} />
export const Defs = props => <defs {...props} />
export const LinearGradient = props => <linearGradient {...props} />
export const Stop = props => <stop {...props} />
