import { TWIN_CITIES } from '../constants'

const regionInTwinCities = region => {
  return (
    Math.abs(region.latitude - TWIN_CITIES.latitude) < TWIN_CITIES.latitudeDelta &&
    Math.abs(region.longitude - TWIN_CITIES.longitude) < TWIN_CITIES.longitudeDelta
  )
}

export default regionInTwinCities
