import * as most from 'most'
import { combineEpics } from 'redux-observable'
import { select } from 'redux-most'
import createAPI from '../api/stopSchedule'
import mirrorKeys from 'utils/mirrorKeys'
import { assoc } from 'ramda'

export const EVENTS = mirrorKeys(
  ['STORE_STOP', 'FETCH_STOP_SCHEDULE', 'FETCH_STOP_SCHEDULE_ERROR', 'FETCH_STOP_SCHEDULE_SUCCESS'],
  'stopSchedules/'
)

const initialState = {
  schedules: {},
  loading: false,
  error: null,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EVENTS.FETCH_STOP_SCHEDULE:
      return {
        ...state,
        loading: true,
      }

    case EVENTS.FETCH_STOP_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        schedules: assoc(payload.stop_id, payload, state.schedules),
      }

    case EVENTS.FETCH_STOP_SCHEDULE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      }

    default:
      return state
  }
}

const fetchStopSchedule = siteid => ({
  type: EVENTS.FETCH_STOP_SCHEDULE,
  payload: {
    siteid,
  },
})

const fetchStopScheduleSuccess = response => ({
  type: EVENTS.FETCH_STOP_SCHEDULE_SUCCESS,
  error: false,
  payload: JSON.parse(response.responseData),
})

const fetchStopScheduleError = error => ({
  type: EVENTS.FETCH_STOP_SCHEDULE_ERROR,
  error: true,
  payload: error,
})

export const actions = {
  fetchStopSchedule,
  fetchStopScheduleSuccess,
  fetchStopScheduleError,
}

export const fetchStopScheduleEpic = (action$, store) =>
  action$.thru(select(EVENTS.FETCH_STOP_SCHEDULE)).flatMap(({ payload }) => {
    const sitePath = `${payload.siteid
      .toString()
      .split('')
      .join('/')}.json`

    return most
      .fromPromise(createAPI(store).Schedules.forStop({ sitePath }))
      .flatMap(response => {
        return most.of(fetchStopScheduleSuccess(response, payload.startTime))
      })
      .recoverWith(error => most.of(fetchStopScheduleError(error, payload.startTime)))
  })

export const epic = combineEpics(fetchStopScheduleEpic)
