export const STOPS_LAYER = {
  id: 'mngis_stops',
  type: 'symbol',
  source: 'stops',
  'source-layer': 'stops',
  layout: {
    'icon-image': ['to-string', ['get', 'pinId']],
    'icon-offset': [0, -2],
    'icon-size': 0.75,
    'icon-allow-overlap': ['step', ['zoom'], false, 14, true],
  },
  paint: { 'icon-translate': [0, -10] },
}
