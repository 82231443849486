import { path } from 'ramda'
import departureFilterForHiddens from 'utils/departureFilterForHiddens'

export const stopForSiteId = (state, siteid) => path(['busStop', 'stopIndex', siteid], state)

export const savedStopForSiteId = (state, siteid) => {
  const savedStops = path(['savedStops', 'savedStopList'], state)
  if (!savedStops) return null

  return Object.values(savedStops).find(savedStop => {
    const savedSiteId = path(['stop', 'id'], savedStop)

    return `${savedSiteId}` === `${siteid}`
  })
}

export const getVisibleDepartures = (state, siteid) => {
  const departureList = state.nextrip.departureList || []
  const savedStop = savedStopForSiteId(state, siteid)

  const hiddenRoutes = savedStop ? savedStop.hiddenRoutes : departureList

  const visibleFilter = departureFilterForHiddens(hiddenRoutes)
  return departureList.filter(visibleFilter)
}

export const stopScheduleData = (state, siteid) => {
  return path(['stopSchedules', 'schedules', siteid])(state)
}

export const stopSchedulesLoading = path(['stopSchedules', 'loading'])

export const stopSchedulesError = path(['stopSchedules', 'error'])

export const vehicleLocations = path(['vehicleLocations', 'locations'])
export const vehicleLocationsLoading = path(['vehicleLocations', 'loading'])
