import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import getRouteParam from 'utils/getRouteParam'
import { actions as savedStopActions } from 'store/savedStops'
import { actions as busStopActions } from 'store/busStop'
import { stopForSiteId } from 'store/selectors'
import withNavigation from 'navigation/withNavigation'
import ROUTE_NAMES from 'navigation/routeNames'
import Loader from 'components/ui/Loader'
import StopDetailComponent from './StopDetail'
import analytics from 'utils/analytics'

const mapStateToProps = (state, ownProps) => {
  const siteid = getRouteParam(ownProps, 'siteid')

  return {
    siteid: parseInt(siteid, 10),
    stop: stopForSiteId(state, siteid),
  }
}

const mapDispatchToProps = {
  saveStop: savedStopActions.saveStop,
  fetchStop: busStopActions.fetchStop,
}

class StopDetail extends Component {
  componentDidMount() {
    if (!this.props.stop) {
      this.props.fetchStop(this.props.siteid)
    }
  }

  viewDepartures = () => {
    this.props.navigate(ROUTE_NAMES.DEPARTURE_LIST, { siteid: this.props.siteid })
  }

  handleSaveStop = (stop, nickname) => {
    this.props.saveStop(stop, nickname)

    analytics().logEvent('save_stop', {})

    this.props.goHome()
  }

  render() {
    const { stop } = this.props

    if (!stop) {
      return <Loader />
    }

    return (
      <StopDetailComponent
        stop={stop}
        saveStop={this.handleSaveStop}
        viewDepartures={this.viewDepartures}
      />
    )
  }
}

StopDetail.propTypes = {
  siteid: PropTypes.number.isRequired,
  stop: PropTypes.object,
  saveStop: PropTypes.func.isRequired,
  fetchStop: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigation(StopDetail))
