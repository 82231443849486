import React from 'react'
import { Svg, Path } from 'react-native-svg'

interface Props {
  color: string
  size: number
}

const Map: React.FC<Props> = ({ size = 44, color = '#999' }) => (
  <Svg viewBox="0 0 3461 3558" width={size} height={size}>
    <Path
      d="M2279 498L3342 7c42-19 91-1 110 41 5 11 8 23 8 35v2729c0 32-18 59-44 73l-1093 660c-2 1-4 3-7 4l-1 1h-3c-10 5-21 7-32 7s-22-2-32-7h-3l-1-1c-2-1-5-2-7-4l-1054-636-1055 637c-39 24-91 11-114-28-8-13-12-28-12-43V590c0-36 22-66 54-78L1149 8h2l2-1h1c8-3 16-5 25-5h10c8 1 17 2 25 5h1l2 1h2l1063 491zM1098 2765V213L167 643v2684l931-562zm1098 562V643l-931-430v2552l931 562zM3294 213l-931 430v2684l931-562V213z"
      fillRule="nonzero"
      fill={color}
    />
  </Svg>
)

export default Map
