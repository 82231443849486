import React from 'react'
import ROUTES from 'navigation/routes'
import getDisplayName from 'utils/getComponentDisplayName'
import history from './history'
import analytics from 'utils/analytics'

const pathWithParams = (route, params = {}) => {
  const queryParams = {}
  let path = route.path

  Object.keys(params).forEach(key => {
    if (path.indexOf(`:${key}`) !== -1) {
      path = path.replace(`:${key}`, params[key])
    } else {
      queryParams[key] = params[key]
    }
  })
  return path
}

const withNavigation = WrappedComponent => {
  const WithNavigation = props => {
    const navigate = (routeName, params) => {
      const route = ROUTES[routeName]

      history.push(pathWithParams(route, params), params)

      analytics().setCurrentScreen(routeName)
    }

    const goBack = () => {
      history.goBack()
    }

    const goHome = () => {
      history.push('/')
    }

    return <WrappedComponent navigate={navigate} goBack={goBack} goHome={goHome} {...props} />
  }

  WithNavigation.displayName = `WithNavigation(${getDisplayName(WrappedComponent)})`

  return WithNavigation
}

export default withNavigation
