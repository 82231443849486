import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Text, Image, TextInput, Button, KeyboardAvoidingView } from 'react-native'
import URI from 'urijs'
import { GOOGLE_STREETVIEW_API } from '../../config'
import { GOOGLE_STREETVIEW_URL } from '../../constants'
import BusStopModel from 'models/BusStopModel'
import { withTranslation } from 'react-i18next'
import { isWeb } from 'utils/platformSelect'
import Header from 'components/ui/Header'

const styles = {
  screenContainer: {
    backgroundColor: '#fff',
  },
  streetview: {
    height: 200,
    top: 0,
    left: 0,
  },
  location: {
    fontSize: 22,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  cornDescription: {
    fontSize: 14,
    textAlign: 'center',
  },
  directions: {
    fontSize: 14,
    textAlign: 'center',
  },
  giveNickname: {
    fontSize: 16,
    textAlign: 'center',
  },
  nicknameInput: {
    fontSize: 22,
    backgroundColor: 'white',
    color: 'black',
    margin: 5,
    padding: 5,
  },
}

class StopDetail extends Component {
  state = {
    nickname: '',
  }

  getStreetViewUrl = stop => {
    const [x, y] = stop.geometry.coordinates
    var streetview_params = {
      size: '500x250',
      location: `${y},${x}`,
      key: isWeb ? GOOGLE_STREETVIEW_API.web : GOOGLE_STREETVIEW_API.ios,
    }

    let image_uri = new URI(GOOGLE_STREETVIEW_URL)
    image_uri.search(streetview_params)

    return image_uri.toString()
  }

  viewDepartures = () => {
    this.props.viewDepartures()
  }

  saveStop = () => {
    this.props.saveStop(this.props.stop, this.state.nickname)
  }

  render() {
    const stopModel = BusStopModel.fromStopFeature(this.props.stop)

    const t = this.props.t
    const locationLabel = stopModel.getLocationLabel()
    const directions = stopModel.getDirections(t)

    return (
      <KeyboardAvoidingView
        style={styles.screenContainer}
        behavior="position"
        keyboardVerticalOffset={90}
      >
        {isWeb ? <Header title={t('title')} /> : null}

        <Text style={styles.location}>{locationLabel}</Text>
        <Text style={styles.cornDescription}>{stopModel.CORN_DESC}</Text>
        <Text style={styles.directions}>{directions}</Text>
        <Button
          title={t('view_departures')}
          accessibilityLabel="View departures for this stop"
          onPress={this.viewDepartures}
        />
        <Image
          style={styles.streetview}
          resizeMode="contain"
          source={{ uri: this.getStreetViewUrl(this.props.stop) }}
        />
        <Text style={styles.giveNickname}>{t('give_nickname')}</Text>
        <TextInput
          style={styles.nicknameInput}
          onChangeText={value => this.setState({ nickname: value })}
          placeholder={t('give_nickname_placeholder')}
        />
        <Button
          title={t('save_this_stop')}
          onPress={this.saveStop}
          accessibilityLabel="Learn more about this purple button"
        />
      </KeyboardAvoidingView>
    )
  }
}

StopDetail.propTypes = {
  t: PropTypes.func.isRequired,
  stop: PropTypes.object.isRequired,
  saveStop: PropTypes.func.isRequired,
  viewDepartures: PropTypes.func.isRequired,
}

export default withTranslation('stopDetail')(StopDetail)
