import MyStopsList from 'components/MyStopsList'
import FindStop from 'components/FindStop'
import RouteMap from 'components/RouteMap'
import DepartureList from 'components/DepartureList'
import StopSchedule from 'components/StopSchedule'
import DeparturesHelp from 'components/DeparturesHelp'
import StopDetail from 'components/StopDetail'
import PrivacyPolicy from 'components/PrivacyPolicy'
import ROUTE_NAMES from 'navigation/routeNames'

const ROUTES = {
  [ROUTE_NAMES.PRIVACY_POLICY]: { path: '/privacy', titleKey: 'privacy', component: PrivacyPolicy },
  [ROUTE_NAMES.MY_STOPS]: { path: '/', titleKey: 'my_stops', component: MyStopsList },
  [ROUTE_NAMES.FIND_STOP]: { path: '/find', titleKey: 'find_my_stop', component: FindStop },
  [ROUTE_NAMES.ROUTE_MAP]: {
    path: '/route/:routeNumber',
    titleKey: 'route_map',
    component: RouteMap,
  },
  [ROUTE_NAMES.DEPARTURE_LIST]: {
    path: '/departures/:siteid',
    titleKey: 'departure_list',
    component: DepartureList,
  },
  [ROUTE_NAMES.STOP_SCHEDULE]: {
    path: '/schedule/:siteid',
    titleKey: 'stop_schedule',
    component: StopSchedule,
  },
  [ROUTE_NAMES.DEPARTURES_HELP]: {
    path: '/help',
    titleKey: 'departures_help',
    component: DeparturesHelp,
  },
  [ROUTE_NAMES.STOP_DETAIL]: { path: '/stop/:siteid', title: 'stop_detail', component: StopDetail },
}

export default ROUTES
