import { ROUTE_REGION_PADDING } from '../constants'

const processRouteGeometry = paths => {
  let minLongitude = Number.MAX_VALUE,
    maxLongitude = -Number.MAX_VALUE,
    minLatitude = Number.MAX_VALUE,
    maxLatitude = -Number.MAX_VALUE

  const polylines = paths.map(path =>
    path.map(coord => {
      const [longitude, latitude] = coord
      if (longitude < minLongitude) minLongitude = longitude
      if (longitude > maxLongitude) maxLongitude = longitude
      if (latitude < minLatitude) minLatitude = latitude
      if (latitude > maxLatitude) maxLatitude = latitude
      return { latitude, longitude }
    })
  )

  const latitude = 0.5 * (minLatitude + maxLatitude)
  const longitude = 0.5 * (minLongitude + maxLongitude)
  const latitudeDelta = maxLatitude - minLatitude + ROUTE_REGION_PADDING
  const longitudeDelta = maxLongitude - minLongitude + ROUTE_REGION_PADDING
  const routeRegion = {
    latitude,
    longitude,
    latitudeDelta,
    longitudeDelta,
  }

  return { polylines, routeRegion }
}

export default processRouteGeometry
