import * as most from 'most'
import createAPI from 'api/mngisRouteGeometry'
import { DEFAULT_REGION } from '../constants'
import processRouteGeometry from 'utils/process_route_geometry'

export const EVENTS = {
  CLEAR_GEOMETRY: 'mngis/CLEAR_GEOMETRY',
  FETCH_GEOMETRY: 'mngis/FETCH_GEOMETRY',
  FETCH_GEOMETRY_FAILURE: 'mngis/FETCH_GEOMETRY_FAILURE',
  FETCH_GEOMETRY_SUCCESS: 'mngis/FETCH_GEOMETRY_SUCCESS',
}

const initialState = {
  polylines: null,
  routeRegion: DEFAULT_REGION,
  error: null,
  newData: false,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EVENTS.CLEAR_GEOMETRY: {
      return initialState
    }
    case EVENTS.FETCH_GEOMETRY: {
      return {
        newData: false,
      }
    }
    case EVENTS.FETCH_GEOMETRY_SUCCESS: {
      return {
        ...state,
        polylines: action.payload.polylines,
        routeRegion: action.payload.routeRegion,
        newData: true,
      }
    }
    case EVENTS.FETCH_GEOMETRY_FAILURE: {
      console.log('FETCH_GEOMETRY_FAILURE')
      console.log(action.error)
      return {
        ...state,
        geometry: [],
        newData: false,
      }
    }
    default:
      return state
  }
}

const fetchGeometry = (route, terminal) => {
  return { type: EVENTS.FETCH_GEOMETRY, payload: { route, terminal } }
}
const fetchGeometrySuccess = paths => {
  return {
    type: EVENTS.FETCH_GEOMETRY_SUCCESS,
    payload: processRouteGeometry(paths),
  }
}
const fetchGeometryFailure = error => {
  return { type: EVENTS.FETCH_GEOMETRY_FAILURE, error: true, payload: error }
}
const clearGeometry = () => {
  return { type: EVENTS.CLEAR_GEOMETRY, error: false }
}

export const actions = {
  fetchGeometry,
  fetchGeometrySuccess,
  fetchGeometryFailure,
  clearGeometry,
}

export const epic = action$ =>
  action$
    .filter(action => action.type === EVENTS.FETCH_GEOMETRY)
    .flatMap(({ payload }) => {
      return most
        .fromPromise(createAPI().Geometry.forRoute({ route: payload.route }))
        .flatMap(response => {
          const paths = response.data().features[0].geometry.paths
          return most.of(fetchGeometrySuccess(paths))
        })
        .recoverWith(error => most.of(fetchGeometryFailure(error)))
    })
