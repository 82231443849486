export const MNGIS_URL = 'https://mngis-proxy.herokuapp.com'
export const MNGIS_SERVICE_PATH = '/ArcGIS/rest/services/transit'

export const GOOGLE_STREETVIEW_API = {
  ios: 'AIzaSyADUDkW0YZ2HUx1jGibSHKuY4rtta7p7W4',
  android: 'AIzaSyD3m0oS5GEqSdS3QbvjZD4F0GVQWubwm2o',
  web: 'AIzaSyAWx_ML80Z2UNsKZ69b9Rsf30EE1i9is3s',
}

export const ADMOB_AD_UNIT = {
  ios: 'ca-app-pub-1206509752801316/1509895607',
  android: 'ca-app-pub-1206509752801316/9299822804',
}

export const GOOGLE_ANALYTICS_ID = 'UA-6502690-5'

export const GOOGLE_MAPS_WEB_KEY = 'AIzaSyAWx_ML80Z2UNsKZ69b9Rsf30EE1i9is3s'

export const MAPBOX_STYLE_URL = 'mapbox://styles/jbeuckm/ck1h6wpne5tk41cp67a66n8ah'
export const MAPBOX_STYLE_URL_NO_STOPS = 'mapbox://styles/jbeuckm/ck31n2brc01k81cnkv7ucj4vn'

export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoiamJldWNrbSIsImEiOiJjajhvOTdiaXAwMHFoMzNubnUyeWd5b3ZiIn0.qbmerZkVwFqby1cncanbvg'
