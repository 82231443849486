import * as most from 'most'
import createAPI from '../api/vehicleLocations'
import mirrorKeys from 'utils/mirrorKeys'

export const EVENTS = mirrorKeys(
  ['CLEAR_LOCATIONS', 'FETCH_LOCATIONS', 'FETCH_LOCATIONS_FAILURE', 'FETCH_LOCATIONS_SUCCESS'],
  'vehicleLocations/'
)

const initialState = {
  loading: false,
  locations: [],
  error: null,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EVENTS.CLEAR_LOCATIONS: {
      return {
        ...state,
        locations: [],
        loading: true,
      }
    }
    case EVENTS.FETCH_LOCATIONS: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }
    case EVENTS.FETCH_LOCATIONS_SUCCESS: {
      return {
        ...state,
        locations: action.payload.locations,
        error: null,
        loading: false,
      }
    }
    case EVENTS.FETCH_LOCATIONS_FAILURE: {
      return {
        ...state,
        departureList: [],
        isFetching: false,
        error: action.error,
      }
    }
    default:
      return state
  }
}

const clearVehicleLocations = () => ({ type: EVENTS.CLEAR_LOCATIONS })

const fetchVehicleLocations = route => ({ type: EVENTS.FETCH_LOCATIONS, payload: { route } })

const fetchVehicleLocationsSuccess = locations => ({
  type: EVENTS.FETCH_LOCATIONS_SUCCESS,
  payload: { locations },
})

const fetchVehicleLocationsFailure = error => ({
  type: EVENTS.FETCH_LOCATIONS_FAILURE,
  error,
})

export const actions = {
  clearVehicleLocations,
  fetchVehicleLocations,
  fetchVehicleLocationsSuccess,
  fetchVehicleLocationsFailure,
}

export const epic = (action$, store) =>
  action$
    .filter(action => action.type === EVENTS.FETCH_LOCATIONS)
    .flatMap(({ payload }) => {
      return most
        .fromPromise(createAPI(store).Locations.forRoute(payload))
        .flatMap(response => {
          return most.of(fetchVehicleLocationsSuccess(response.data()))
        })
        .recoverWith(error => most.of(fetchVehicleLocationsFailure(error)))
    })
