import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import getLocale from './getLocale'
import analytics from 'utils/analytics'

const locale = getLocale()
analytics().setUserProperties({ locale })

let language = locale.indexOf('es') === 0 ? 'es_US' : 'en_US'

let resources
switch (language) {
  case 'es':
  case 'es_US':
    resources = require('./es_US.json')
    break

  case 'en_US':
  case 'so_US':
  default:
    resources = require('./en_US.json')
    break
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language,
    fallbackLng: 'en_US',

    interpolation: {
      escapeValue: false,
    },
  })
