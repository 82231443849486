import React from 'react'
import { View, Text } from 'react-native'
import { groupBy, map, mapObjIndexed, pipe, pluck, prop } from 'ramda'
import ScheduleModel from 'models/ScheduleModel'
import AlertIcon from 'components/ui/icons/Alert'

const formatHour = rawHour => {
  const half = ['am', 'pm'][Math.floor(rawHour / 12) % 2]
  const hour = rawHour % 12

  return `${hour === 0 ? '12' : hour}${half}`
}

const ScheduleItem = props => {
  const { schedule } = props
  const scheduleModel = new ScheduleModel(schedule)

  const departuresByHour = pipe(
    map(time => {
      const [hour, minute] = time.split(':')
      return { hour, minute }
    }),
    groupBy(prop('hour')),
    mapObjIndexed(pluck('minute'))
  )(schedule.departures)

  return (
    <View
      style={{
        marginBottom: 20,
        borderColor: scheduleModel.isException ? '#ec0' : null,
        borderLeftWidth: 2,
        marginLeft: 5,
      }}
    >
      {scheduleModel.isException && (
        <View style={{ flexDirection: 'row', alignItems: 'center', paddingLeft: 5 }}>
          <AlertIcon color="#ec0" size={35} />

          <Text
            style={{
              color: '#fff',
              fontStyle: 'italic',
              fontSize: 20,
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
          >
            reduced service dates:
          </Text>
        </View>
      )}
      <Text style={{ color: '#fff', padding: 15, fontSize: 22, fontWeight: '800' }}>
        {scheduleModel.periodLabel()}
      </Text>

      {Object.keys(departuresByHour)
        .sort()
        .map(hour => {
          const minutes = departuresByHour[hour]

          return (
            <View key={hour} style={{ flexDirection: 'row' }}>
              <Text
                style={{
                  color: 'white',
                  width: '25%',
                  borderRightColor: '#fff',
                  borderRightWidth: 2,
                  textAlign: 'right',
                  paddingRight: 12,
                  fontSize: 16,
                  paddingBottom: 4,
                }}
              >
                {formatHour(hour)}
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  width: '75%',
                  flexWrap: 'wrap',
                  paddingBottom: 10,
                }}
              >
                {minutes.map((minute, index) => (
                  <Text key={index} style={{ color: 'white', marginLeft: 12, fontSize: 16 }}>
                    :{minute}
                  </Text>
                ))}
              </View>
            </View>
          )
        })}
    </View>
  )
}

export default ScheduleItem
