import * as most from 'most'
import { combineEpics } from 'redux-observable'
import { select } from 'redux-most'
import createAPI from '../api/busStop'
import mirrorKeys from 'utils/mirrorKeys'
import { assoc } from 'ramda'

export const EVENTS = mirrorKeys(
  ['STORE_STOP', 'FETCH_STOP', 'FETCH_STOP_FAILURE', 'FETCH_STOP_SUCCESS'],
  'busStop/'
)

const initialState = {
  stopIndex: {},
  isLoading: false,
  error: null,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EVENTS.FETCH_STOP:
      return {
        ...state,
        isLoading: true,
      }

    case EVENTS.FETCH_STOP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stopIndex: assoc(payload.stop.id, payload.stop, state.stopIndex),
      }

    case EVENTS.FETCH_STOP_FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
      }

    case EVENTS.STORE_STOP:
      return { ...state, stopIndex: assoc(payload.stop.id, payload.stop, state.stopIndex) }

    default:
      return state
  }
}

const storeStop = stop => ({
  type: EVENTS.STORE_STOP,
  payload: {
    stop,
  },
})

const fetchStop = siteid => ({
  type: EVENTS.FETCH_STOP,
  payload: {
    siteid,
  },
})

const fetchStopSuccess = response => {
  const stop = JSON.parse(response.responseData)
  return { type: EVENTS.FETCH_STOP_SUCCESS, error: false, payload: { stop } }
}

const fetchStopFailure = error => {
  return { type: EVENTS.FETCH_STOP_FAILURE, error: true, payload: error }
}

export const actions = {
  storeStop,
  fetchStop,
  fetchStopSuccess,
  fetchStopFailure,
}

export const fetchStopEpic = (action$, store) =>
  action$.thru(select(EVENTS.FETCH_STOP)).flatMap(({ payload }) => {
    const sitePath = `${payload.siteid
      .toString()
      .split('')
      .join('/')}.json`
    return most
      .fromPromise(createAPI(store).Stop.bySitePath({ sitePath }))
      .flatMap(response => {
        return most.of(fetchStopSuccess(response, payload.startTime))
      })
      .recoverWith(error => most.of(fetchStopFailure(error, payload.startTime)))
  })

export const epic = combineEpics(fetchStopEpic)
