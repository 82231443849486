interface Feature {
  properties: any
}

class BusStopModel {
  attributes: any

  static fromStop(obj: any) {
    return Object.assign(new BusStopModel(), obj)
  }

  static fromStopFeature(feature: Feature) {
    return Object.assign(new BusStopModel(), { attributes: feature.properties })
  }

  getLocationLabel() {
    if (!this.attributes) {
      return 'none'
    }

    if (!this.attributes.site_at || this.attributes.site_at.toLowerCase() === 'null') {
      return this.attributes.site_on.trim()
    }
    return this.attributes.site_on.trim() + ' @ ' + this.attributes.site_at.trim()
  }

  getDirections(t: Function) {
    if (!this.attributes) {
      return 'none'
    }
    return this.attributes.ROUTEDIRS.replace(/WB/g, `(${t('west')})`)
      .replace(/EB/g, `(${t('east')})`)
      .replace(/SB/g, `(${t('south')})`)
      .replace(/NB/g, `(${t('north')})`)
      .split(/[,:]/g)
      .join(' ')
  }
}

export default BusStopModel
