import React from 'react'
import { Svg, Path, G, Line, Circle } from 'react-native-svg'

interface Props {
  color: string
  size: number
}

const Map: React.FC<Props> = ({ size = 44, color }) => (
  <Svg width={size} height={size} viewBox="0 0 512 512">
    <G transform={'translate(-256, -256), scale(2)'}>
      <Path
        d="   M261.2,193.4c-46.4,0-86.8,25.2-108.5,62.6c21.7,37.4,62.1,62.6,108.5,62.6c46.4,0,86.8-25.2,108.5-62.6   C348,218.6,307.5,193.4,261.2,193.4z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
      />

      <Circle
        cx="261.2"
        cy="256"
        fill="none"
        r="37.7"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
      />

      <Line
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        x1="152.7"
        x2="369.6"
        y1="348.5"
        y2="170"
      />
    </G>
  </Svg>
)

export default Map
