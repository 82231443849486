const geojsonFromPolylines = polylines => {
  return {
    type: 'MultiLineString',
    coordinates:
      polylines &&
      polylines.map(line => line.map(({ latitude, longitude }) => [longitude, latitude])),
  }
}

export default geojsonFromPolylines
