import daysFromDayFlags from './daysFromDayFlags'
import dateFromSchedule from 'utils/dateFromSchedule'
import { groupBy, map, mapObjIndexed, pipe, pluck, prop, reject, isNil } from 'ramda'
import moment from 'moment'

const dateFormat = 'MMMM Do'

interface Schedule {
  trip_headsign: string
  route_long_name: string
  days: string
  start_date: number
  end_date: number
  exception_dates: Array<number | null>
  departures: Array<string>
}

class ScheduleModel {
  trip_headsign: string
  route_long_name: string
  days: string
  start_date: number
  end_date: number
  exception_dates: Array<number | null>
  departures: Array<string>

  constructor(schedule: Schedule) {
    this.trip_headsign = schedule.trip_headsign
    this.route_long_name = schedule.route_long_name
    this.days = schedule.days
    this.start_date = schedule.start_date
    this.end_date = schedule.end_date
    this.exception_dates = schedule.exception_dates || []
    this.departures = schedule.departures || []
  }

  get isException() {
    const exceptions = reject(isNil)(this.exception_dates)
    return exceptions.length > 0
  }

  get isToday() {
    const today = new Date(Date.now())
    const todayInt = parseInt(moment(today).format('YYYYMMDD'))

    if (this.exception_dates && reject(isNil, this.exception_dates).length > 0) {
      return this.exception_dates.includes(todayInt)
    }

    if (todayInt < this.start_date || todayInt > this.end_date) {
      return false
    }

    const daysArray = this.days && this.days.split('').map(day => day === '1')

    return Boolean(daysArray) && daysArray[today.getDay()]
  }

  periodLabel = () => {
    const exceptionDates: Array<Date> = reject(isNil)(this.exception_dates).map(dateFromSchedule)

    if (this.isException) {
      return this.exception_dates.length === 1
        ? moment(exceptionDates[0]).format(dateFormat)
        : exceptionDates.map((d: Date) => moment(d).format('M/D')).join(', ')
    } else {
      return this.normalPeriodLabel()
    }
  }

  normalPeriodLabel = () => {
    if (this.start_date === this.end_date) {
      const date = dateFromSchedule(this.start_date)
      return moment(date).format(dateFormat)
    }

    const startDate = dateFromSchedule(this.start_date)
    const endDate = dateFromSchedule(this.end_date)

    let daysLabel

    switch (this.days) {
      case '1000000':
        daysLabel = 'Sundays'
        break

      case '0000001':
        daysLabel = 'Saturdays'
        break

      case '0111110':
        daysLabel = 'Mon - Fri'
        break

      default:
        daysLabel = this.days && daysFromDayFlags(this.days).join(', ')
    }

    return (
      daysLabel +
      ' ' +
      moment(startDate).format(dateFormat) +
      ' - ' +
      moment(endDate).format(dateFormat)
    )
  }
}

export default ScheduleModel
