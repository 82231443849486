import React from 'react'
import { Svg, Line, Circle } from '../svg'
import { TouchableOpacity } from 'react-native'
import withNavigation from 'navigation/withNavigation'
import ROUTE_NAMES from 'navigation/routeNames'

const styles = {
  line: {
    strokeWidth: 2,
    strokeLinecap: 'round',
  },
}

const SearchIcon = ({ color = '#ffffff', size = 32, onPress, navigate }) => {
  const glassRadius = size / 3
  const glassDiagonal = glassRadius * Math.sin(Math.PI / 2)

  const handleFindStop = () => navigate(ROUTE_NAMES.FIND_STOP)

  return (
    <TouchableOpacity onPress={handleFindStop}>
      <Svg width={size} height={size}>
        <Line
          x1="0"
          x2={size - glassRadius - glassDiagonal}
          y1={size}
          y2={glassRadius + glassDiagonal}
          stroke={color}
          {...styles.line}
        />
        <Circle
          cx={size - glassRadius - 1}
          cy={glassRadius + 1}
          r={glassRadius}
          stroke={color}
          fill="none"
          {...styles.line}
        />
      </Svg>
    </TouchableOpacity>
  )
}

export default withNavigation(SearchIcon)
