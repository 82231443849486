const getVehicleType = routeNumber => {
  switch (routeNumber) {
    case 901:
    case 902:
    case 'Grn':
    case 'Blue':
      return 'lightrail'

    default:
      return 'bus'
  }
}

export default getVehicleType
