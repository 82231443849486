import React from 'react'
import { withTranslation } from 'react-i18next'
import { View, Text } from 'react-native'

const Error = ({ t }) => (
  <View style={{ paddingTop: 30 }}>
    <Text color="#fff">{t('error')}</Text>
  </View>
)

export default withTranslation('ui')(Error)
