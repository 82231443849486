import palette from 'palette'

const colorForRoute = (Route: string) => {
  if (Route === 'Grn') return '#00b100'
  if (Route === 'Blue') return '#0055A5'

  const index: number = parseInt(Route, 10) % palette.length
  return palette[index]
}

export default colorForRoute
