const displayDistance = miles => {
  if (miles < 0.5) {
    const feet = Math.round(miles * 5280)
    return `${feet}ft`
  }

  return `${miles.toFixed(1)} mi`
}

export default displayDistance
